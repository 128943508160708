import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiChevronDown,
} from 'react-icons/fi';
import { faCoffee, faGears, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUser } from '../types';
import { useEffect, useState } from 'react';
import { getUserData } from '../API/userData';
import { useUser } from '../UserContext';

interface MobileProps extends FlexProps {
    onOpen: () => void;
    full?: boolean;
}
export const MobileNav = ({ onOpen, full=false, ...rest }: MobileProps) => {
    const [userData, setUserData] = useState<IUser | null>(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      getUserData().then((response) => {
        setUserData(response.data);
        setLoaded(true);
      });

      // Liste to event UPDATE_USER_DATA
      window.addEventListener('UPDATE_USER_DATA', update_data);
    }, []);

    const update_data = () => {
      getUserData().then((response) => {
        setUserData(response.data);
      });
    }
    
    const { user } = useUser();

    // Return a string with a number formatted with spaces every 3 digits
    const format_number = (number: number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
      <Flex
        ml={{ base: 0, md: full ? 0 : 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={"#131f24"}
        color={"white"}
        borderBottomWidth="3px"
        borderBottomColor={"#37464f"}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}>

        {!full && 
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
            color={"white"}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
          />
        }
  
        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize={{ base: 'xl', md: '2xl' }}
          color={"#0bc5ea"}
          fontWeight={900}>
          Progress Tracker
        </Text>
  
        <HStack spacing={6}>
          <Tooltip p={3} fontWeight={800} bgColor={'#FF9601'} color={'white'} label={"XP"} placement='left' rounded={'xl'} me={3}>
            <HStack cursor={"default"} className='hvr-grow'>
              <Image src="https://d35aaqx5ub95lt.cloudfront.net/images/goals/2b5a211d830a24fab92e291d50f65d1d.svg" height={'40px'} />
              {loaded && 
                <Text mt={1} fontWeight={800} fontSize={'xl'} color={"#ff9600"}>{format_number(userData?.xp || 0)}</Text>
              }
              {!loaded &&
                <Skeleton mt={1} startColor='#ff9600' endColor='#ffa700' height="15px" width="40px" />
              }
            </HStack>
          </Tooltip>
          
          <Tooltip p={3} fontWeight={800} bgColor={'#1CB0F6'} color={'white'} label={"Mes diamants"} placement='left' rounded={'xl'} me={3}>
            <HStack cursor={"default"} className='hvr-grow'>
              <Image src="https://d35aaqx5ub95lt.cloudfront.net/images/gems/45c14e05be9c1af1d7d0b54c6eed7eee.svg" />
              {loaded &&
                <Text mt={1} fontWeight={800} fontSize={'xl'} color={"#1cb0f6"}>{format_number(userData?.diamonds || 0)}</Text>
              }
              {!loaded &&
                <Skeleton mt={1} startColor='#1cb0f6' endColor='#1d97ff' height="15px" width="55px" />
              }
            </HStack>
          </Tooltip>
          
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Avatar
                    size={'sm'}
                    src={
                      ''
                    }
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2">
                    <Text fontSize="sm" fontWeight={800}>{user?.firstname}</Text>
                    <Text fontSize="xs" color="gray.600" fontWeight={500}>
                      {user?.rank == 'admin' ? 'Professor' : 'Student'}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                zIndex={101}
                bg={"#131f24"}
                borderColor={"#37464f"}
                borderWidth={'3px'}
                color={"white"}>
                <MenuItem onClick={() => {
                  window.location.href = "https://cafe.uliege.be";
                }} bg={"#131f24"} icon={<FontAwesomeIcon icon={faCoffee} />}>Back to CAFÉ</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    );
  };