import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
  } from 'react-icons/fi';
import { IconType } from "react-icons";
import { BiSolidTrophy, BiStats } from "react-icons/bi";
import { FaCode, FaHouseUser } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { RiAdminFill, RiFilePaperFill } from "react-icons/ri";
// import question mark
import { FaQuestion } from "react-icons/fa";

interface LinkItemProps {
    name: string;
    path: string;
    icon: IconType;
    secret: boolean;
    external?: boolean;
}
export const LinkItems: Array<LinkItemProps> = [
    { name: 'Tracker', icon: FaMapMarkedAlt, path: '/', secret:false },
    { name: 'Challenges', icon:FaCode, path: 'https://cafe.uliege.be/challenges', secret:false, external:true },
    { name: 'Tutorial', icon:FaQuestion, path: '/tuto', secret:false, external:true },
    // { name: 'Profil', icon: FaHouseUser , path: '/profil' },
    // { name: 'Leaderboard', icon: BiSolidTrophy, path: '/leaderboard', secret:true },
    { name: 'Administration', icon: RiAdminFill, path: '/admin/students', secret:true },
];