import {
  Box,
  Text,
  Circle,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useDisclosure,
  PopoverCloseButton,
  Image,
  Tooltip,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { IStep, StepState, StepType } from '../types';

import flag from '../resources/step/flag.svg';
import button_up_uncomplete from '../resources/step/button_up.svg';
import button_up_uncomplete_1 from '../resources/step/button_up_1.svg';
import button_up_uncomplete_2 from '../resources/step/button_up_2.svg';
import button_up_uncomplete_3 from '../resources/step/button_up_3.svg';
import button_up_uncomplete_4 from '../resources/step/button_up_4.svg';
import button_up_uncomplete_5 from '../resources/step/button_up_5.svg';
import button_up_uncomplete_6 from '../resources/step/button_up_6.svg';
import button_down_uncomplete from '../resources/step/button_down.svg';
import button_down_uncomplete_1 from '../resources/step/button_down_1.svg';
import button_down_uncomplete_2 from '../resources/step/button_down_2.svg';
import button_down_uncomplete_3 from '../resources/step/button_down_3.svg';
import button_down_uncomplete_4 from '../resources/step/button_down_4.svg';
import button_down_uncomplete_5 from '../resources/step/button_down_5.svg';
import button_down_uncomplete_6 from '../resources/step/button_down_6.svg';
import button_up_completed from '../resources/step/button_completed_up.svg';
import button_up_completed_1 from '../resources/step/button_completed_up_1.svg';
import button_up_completed_2 from '../resources/step/button_completed_up_2.svg';
import button_up_completed_3 from '../resources/step/button_completed_up_3.svg';
import button_up_completed_4 from '../resources/step/button_completed_up_4.svg';
import button_up_completed_5 from '../resources/step/button_completed_up_5.svg';
import button_up_completed_6 from '../resources/step/button_completed_up_6.svg';
import button_down_completed from '../resources/step/button_completed_down.svg';
import button_down_completed_1 from '../resources/step/button_completed_down_1.svg';
import button_down_completed_2 from '../resources/step/button_completed_down_2.svg';
import button_down_completed_3 from '../resources/step/button_completed_down_3.svg';
import button_down_completed_4 from '../resources/step/button_completed_down_4.svg';
import button_down_completed_5 from '../resources/step/button_completed_down_5.svg';
import button_down_completed_6 from '../resources/step/button_completed_down_6.svg';
import chest_unlocked from '../resources/step/chest_unlocked.svg';
import chest_locked from '../resources/step/chest_locked.svg';
import chest_open from '../resources/step/chest_open.svg';
import button_up_locked from '../resources/step/button_up_locked.svg';
import button_down_locked from '../resources/step/button_down_locked.svg';
import { useEffect, useState } from 'react';
import tac_sound from '../resources/sounds/tac.wav';
import start_sound from '../resources/sounds/start_lesson.mp3';
import chest_sound from '../resources/sounds/chest_open.mp3';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRemainingQuestionsNumberByStep } from '../API/step';

interface StepTileProps {
  step: IStep;
  index: number;
  hasFlag: boolean;
  moduleIndex: number;
  start_callback: (stepId: string) => Promise<void>;
}

export const StepTile = ({ step, index, hasFlag, moduleIndex, start_callback }: StepTileProps) => {
    const PATH_SPACING = [0, -45, -70, -45, 0, 45, 70, 45];
    const MODULES_COLORS = ["#1cb0f6", "#CE82FF", "#04CD9C", "#FF9601", "#FF86D0", "#FF4B4B", "#CC348D", "#DC8F47"];
    const disclosure = useDisclosure();
    const [isPressed, setIsPressed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMcqAlert, setShowMcqAlert] = useState(false);
    const [remainingUnansweredQuestions, setRemainingUnansweredQuestions] = useState(0);
    const [loadedRemainingUnansweredQuestions, setLoadedRemainingUnansweredQuestions] = useState(false);
    const [tooltipOpened, setTooltipOpened] = useState(false);

    useEffect(() => {
      if (step.in_progress)
        setShowMcqAlert(true);

      if (step.progress_deadline) {
        // Set a timer to remove the alert when the deadline is reached
        const now = new Date();
        const deadline = new Date(step.progress_deadline);
        const timeToDeadline = deadline.getTime() - now.getTime();
        if (timeToDeadline > 0) {
          setTimeout(() => {
            setShowMcqAlert(false);
          }, timeToDeadline);
        }
      }

      // Preload sounds
      new Audio(tac_sound);
      new Audio(start_sound);
    }, []);

    useEffect(() => {
      if (tooltipOpened) {
        getRemainingQuestionsNumberByStep(step._id).then((response) => {
          setRemainingUnansweredQuestions(response.data);
          setLoadedRemainingUnansweredQuestions(true);
        } );
      }
    }, [tooltipOpened]);
  
    const TooltipContent = () => {
      return <Box>
        <Text cursor={'default'} mb={2} fontSize={"lg"} color={"black"} opacity={0.55} fontWeight={900}>{step.title!.length > 0 ? step.title : 'Nameless step'}</Text>
        <Text cursor={'default'} mb={1} fontSize={"md"} color={"black"} opacity={0.55} fontWeight={500}>{step.description!.length > 0 ? step.description : 'Practice!'}</Text>
        
        <Divider mt={2} mb={2} border={'1pt solid black'} opacity={0.55} />

        {loadedRemainingUnansweredQuestions && remainingUnansweredQuestions == 0 &&
          <Text cursor={'default'} mb={5} fontSize={"xs"} color={"black"} opacity={0.55}  fontWeight={800}><FontAwesomeIcon style={{marginRight:'3px'}} icon={faCheck} /> You've answered all the questions in this step!</Text>
        }
        {loadedRemainingUnansweredQuestions && remainingUnansweredQuestions > 1 &&
          <Text cursor={'default'} mb={5} fontSize={"xs"} color={"black"} opacity={0.55}  fontWeight={600}>There are still <span style={{fontWeight:800}}>{remainingUnansweredQuestions} questions</span> to which you can answer in this step.</Text>
        }
        {loadedRemainingUnansweredQuestions && remainingUnansweredQuestions == 1 &&
          <Text cursor={'default'} mb={5} fontSize={"xs"} color={"black"} opacity={0.55}  fontWeight={600}>There is still <span style={{fontWeight:800}}>{remainingUnansweredQuestions} question</span> à to which you can answer in this step.</Text>
        }
        {!loadedRemainingUnansweredQuestions &&
          <Text cursor={'default'} mb={5} fontSize={"xs"} color={"black"} opacity={0.55}  fontWeight={600}>Loading...</Text>
        }

        <Button rounded={'xl'} className='hvr-shrink' variant="brandPrimary" width={"100%"} onClick={() => {start_wrapper()}}>

          {step.state != StepState.COMPLETED &&
            <Flex>
              <Text color={MODULES_COLORS[moduleIndex]} fontWeight={800} mt={1}>PLAY</Text>
              <Text fontWeight={1000} ms={2} mt={1}>+{step.reward}</Text>
              <Image ms={1} width={'20px'} position={'relative'} bottom={'1px'} src="https://d35aaqx5ub95lt.cloudfront.net/images/gems/45c14e05be9c1af1d7d0b54c6eed7eee.svg" />
            </Flex>
          }
          {step.state == StepState.COMPLETED &&
            <Flex>
              <Text color={MODULES_COLORS[moduleIndex]} fontWeight={800} mt={1}>PRACTICE</Text>
              <Text fontWeight={1000} color={'#FF9601'} ms={2} mt={1}>+XP</Text>
              <Image ms={1} width={'25px'} position={'relative'} bottom={'1px'} src="https://d35aaqx5ub95lt.cloudfront.net/images/goals/2b5a211d830a24fab92e291d50f65d1d.svg" />
            </Flex>
          }
        </Button>
        
      </Box>
    }

    const tileImage = () => {
      if (step.state == StepState.UNLOCKED) {
        switch(step.type) {
          case StepType.EXERCISE:
            if (moduleIndex == 0)
              return [button_up_uncomplete_1, button_down_uncomplete_1];
            else if (moduleIndex == 1)
              return [button_up_uncomplete_2, button_down_uncomplete_2];
            else if (moduleIndex == 2)
              return [button_up_uncomplete_3, button_down_uncomplete_3];
            else if (moduleIndex == 3) 
              return [button_up_uncomplete_4, button_down_uncomplete_4];
            else if (moduleIndex == 4) 
              return [button_up_uncomplete_5, button_down_uncomplete_5];
            else
              return [button_up_uncomplete_6, button_down_uncomplete_6];
          case StepType.REWARD:
            return [chest_unlocked, chest_unlocked];
          default:
            if (moduleIndex == 0)
              return [button_up_uncomplete_1, button_down_uncomplete_1];
            else if (moduleIndex == 1)
              return [button_up_uncomplete_2, button_down_uncomplete_2];
            else if (moduleIndex == 2)
              return [button_up_uncomplete_3, button_down_uncomplete_3];
            else if (moduleIndex == 3)
              return [button_up_uncomplete_4, button_down_uncomplete_4];
            else if (moduleIndex == 4)
              return [button_up_uncomplete_5, button_down_uncomplete_5];
            else
              return [button_up_uncomplete_6, button_down_uncomplete_6];
        }
      } else if (step.state == StepState.COMPLETED) {
        switch(step.type) {
          case StepType.EXERCISE:
            if (moduleIndex == 0)
              return [button_up_completed_1, button_down_completed_1];
            else if (moduleIndex == 1)
              return [button_up_completed_2, button_down_completed_2];
            else if (moduleIndex == 2)
              return [button_up_completed_3, button_down_completed_3];
            else if (moduleIndex == 3)
              return [button_up_completed_4, button_down_completed_4];
            else if (moduleIndex == 4)
              return [button_up_completed_5, button_down_completed_5];
            else
              return [button_up_completed_6, button_down_completed_6];
          case StepType.REWARD:
            return [chest_open, chest_open];
          default:
            return [button_up_completed, button_down_completed];
        }
      } else {
        switch(step.type) {
          case StepType.EXERCISE:
            return [button_up_locked, button_down_locked];
          case StepType.REWARD:
            return [chest_locked, chest_locked];
          default:
            return [button_up_locked, button_down_locked];
        }
      }
    }

    const start_wrapper = async () => {
      if (loading)
        return;

      if (step.state != StepState.LOCKED) {
        setLoading(true);
        start_callback(step._id).then(() => {
          setLoading(false);
        });
      }
      const popover_close = document.getElementById("close-"+step._id);
      if (popover_close) {
        popover_close.click();
      }
    }

    const handleMouseDown = () => {
      setIsPressed(true);
      // disclosure.onOpen();
    };
  
    const handleMouseUp = () => {
      setIsPressed(false);
    };

    const play_tac_sound = () => {
      // Play tac sound
      const audio = new Audio(tac_sound);
      audio.play();
      }
  
    const Content = () => {
      if (step.state == StepState.UNLOCKED || step.state == StepState.COMPLETED) {
        if (step.type == StepType.EXERCISE) {
          return <Popover placement='bottom'>
          <PopoverTrigger>
            <Box
              position={"relative"} 
              left={PATH_SPACING[index % PATH_SPACING.length]+"px"}
            >
              <Tooltip 
                zIndex={1}
                ps={4} pe={4} pt={2} pb={2} 
                label={'MCQ in progress, come back quickly!'}
                fontWeight={800}
                fontSize={'md'}
                placement={"right"}
                border={'2pt solid '+MODULES_COLORS[moduleIndex]}
                borderRadius={'lg'}
                bgColor={MODULES_COLORS[moduleIndex]}
                arrowPadding={5}
                className='slow-up-and-down'
                display={showMcqAlert ? 'block' : 'none'}
                isOpen>
                <Image
                  opacity={loading ? 0.5 : 1}
                  draggable={false}
                  src={isPressed ? tileImage()[1] : tileImage()[0]}
                  onClick={loading ? () => {} : () => {
                    disclosure.onOpen(); 
                    play_tac_sound();
                    setTooltipOpened(true);
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp} 
                  cursor={"pointer"} 
                  width={"70px"} 
                  height={"70px"} 
                />
              </Tooltip>
            </Box>
          </PopoverTrigger>
          <PopoverContent width={'370px'} rounded={"2xl"} bgColor={MODULES_COLORS[moduleIndex]} border={"none"}>
          <PopoverCloseButton opacity={0} visibility={"hidden"} id={'close-'+step._id} />
            <PopoverArrow bgColor={MODULES_COLORS[moduleIndex]} border={"none"} shadow={"none"}/>
            <PopoverBody p={5} width={'370px'}>{TooltipContent()}</PopoverBody>
          </PopoverContent>
        </Popover>
        } else {
          // it is a reward
          return <Image
            draggable={false}
            onClick={start_wrapper}
            src={tileImage()[0]}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp} 
            cursor={step.state == StepState.COMPLETED ? "default" : "pointer"} 
            position={"relative"} 
            left={PATH_SPACING[index % PATH_SPACING.length]+"px"} 
            width={"95px"} 
            height={"95px"} 
          />
        }
      } else {
        return <Box
        position={"relative"} 
        left={PATH_SPACING[index % PATH_SPACING.length]+"px"}
        ><Image
          draggable={false}
          src={isPressed ? tileImage()[1] : tileImage()[0]}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp} 
          cursor={"pointer"}
          width={step.type == StepType.REWARD ? "95px" : "70px"} 
          height={step.type == StepType.REWARD ? "95px" : "70px"}
        />
      </Box>
      }
    }
  
    return Content();
  }